import React from 'react'
import Layout from '../layout'
import { TranslatedLink } from '../TranslatedLink'
import { getTranslateFunction } from '../../utilities'
import LinkBtn from '../linkBtn/LinkBtn'
import PageTitle from '../pageTitle'

const NotFoundPageTemplate = ({ lang }) => {
  const t = getTranslateFunction(lang)
  
  return (
    <Layout showBackButton={true}>
      <div className={'container'} id={'NotFoundPage'}>
        <img src={t('404.Img')} alt={'Confused Travolta'}/>
        <PageTitle title={t('404.Title')} noIndex={ true } />
        <LinkBtn className="loaded">
          <TranslatedLink to="/en">{t('404.BackBtn')}</TranslatedLink>
        </LinkBtn>
      </div>
    </Layout>
  )

}
export default NotFoundPageTemplate
